import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { Layout, Row, Col, Button, Icon, Menu, Card, Dropdown, Message, Select } from 'antd'
import 'assets/css/home.less'
import SiderMenu from '../components/Home/SiderMenu'
import HomeRoutes from '../routes/HomeRoutes'
import HomeCrumb from '../components/Home/HomeCrumb'
import HomeMessage from '../components/Home/HomeMessage'
import ForceChangePassword from 'coms/Home/ForceChangePassword'
import auth from 'assets/img/auth.png'
import { updateLastBusiness, getUserInfo } from 'api/login'

const { Option } = Select;

const { Sider, Header, Content, Footer } = Layout;

Message.config({
    duration : 5,
    maxCount: 1,
  });



class Home extends Component {


    constructor(props){
        super(props);
        this.state={
            collapsed : false,
            current : '',
            username : '',
            RoutesMenu : [],
            RoutesLink : [],
            changePassVisible : false,
            businessCode : '',
            businessList : [],
        }
    }

    getRoutesLink(items , crumbArr = []){
        items.map(item => {
            let crumbArrTemp = [];
            crumbArrTemp.push(...crumbArr);
            crumbArrTemp.push(item.name);
            if(item.children){
                this.getRoutesLink(item.children,crumbArrTemp);
            }else{
                item.crumbs = crumbArrTemp;
                this.state.RoutesLink.push(item);
            }
            return true;
        })
        
    }

    componentWillMount() {
        window._ROUTER_ = this.props.history;
        const user = JSON.parse(global._sessionStorage.getItem("user"));
        if(user !== null){

            let userLoginParam = {  mobile : user.mobile }
            getUserInfo(userLoginParam).then(res => {
                let { errorCode,errorMessage,data } = res.data;
                if(errorCode==='0000'){
                    global._sessionStorage.setItem('loginBusiness',data.loginBusiness);
                    global._sessionStorage.setItem('businessList',JSON.stringify(data.businessList));
                    global._sessionStorage.setItem('loginInfoMap',JSON.stringify(data.loginInfoMap));
                    if(data.loginInfoMap[data.loginBusiness]){
                        let loginInfo = data.loginInfoMap[data.loginBusiness];
                        user.businessCode = data.loginBusiness;
                        user.userId = loginInfo.userId;
                        user.roleId = loginInfo.roleId;
                        user.branchId = loginInfo.branchId;
                        user.isChangePass = data.isChangePass;
                        user.lastLoginTime = data.lastLoginTime;
                        user.personalSign = loginInfo.personalSign;
                        user.companySign = loginInfo.companySign;
                        global._sessionStorage.setItem('user',JSON.stringify(user));
                        global._sessionStorage.setItem('menuList',JSON.stringify(loginInfo.menuTrees));
                        global._sessionStorage.setItem('businessEncrypt',loginInfo.businessEncrypt);
                        global._sessionStorage.setItem('business',JSON.stringify(loginInfo.businessInfo));
                    }
                }else{
                    Message.error(errorMessage);
                }
            })

            const menuList = JSON.parse(global._sessionStorage.getItem("menuList"));
            this.setState({
                username: user.mobile,
                RoutesMenu : menuList,
            });
            this.getRoutesLink(menuList);
            if(user.isChangePass && user.isChangePass === '0'){
                this.setState({
                    changePassVisible : true
                })
            }
        }
        this.setState({
            businessCode : global._sessionStorage.getItem("loginBusiness"),
            businessList : JSON.parse(global._sessionStorage.getItem("businessList")),
        })

    }

    businessChange(value){
        global._sessionStorage.setItem('loginBusiness',value);
        let loginParam = JSON.parse(global._sessionStorage.getItem("user"));
        let loginInfoMap = JSON.parse(global._sessionStorage.getItem("loginInfoMap"));
        if(loginInfoMap[value]){
            let loginInfo = loginInfoMap[value];
            loginParam.businessCode = value;
            loginParam.userId = loginInfo.userId;
            loginParam.roleId = loginInfo.roleId;
            loginParam.branchId = loginInfo.branchId;
            loginParam.personalSign = loginInfo.personalSign;
            loginParam.companySign = loginInfo.companySign;
            loginParam.dataRange = loginInfo.dataRange;
            global._sessionStorage.setItem('user',JSON.stringify(loginParam));
            global._sessionStorage.setItem('menuList',JSON.stringify(loginInfo.menuTrees));
            global._sessionStorage.setItem('businessEncrypt',loginInfo.businessEncrypt);
            global._sessionStorage.setItem('business',JSON.stringify(loginInfo.businessInfo));
            this.setState({
                username: loginParam.mobile,
                RoutesMenu : loginInfo.menuTrees,
                RoutesLink : [],
                current : '/index'
            },()=>{
                this.getRoutesLink(loginInfo.menuTrees);
                this.props.history.push('/index');
                this.refs.setOpenKeys.setOpenKeys();
            });
            
        }

        let lastBusinessParam = {  mobile : loginParam.mobile, 
                                    loginBusiness : value,
        }
        updateLastBusiness(lastBusinessParam).then(res => {

        })
        
    }


    siderHandleClick(key) {
        this.setState({
            current: key
        });
    }

    toggleCollapsed() {
        this.setState({
          collapsed: !this.state.collapsed,
        });
      }

    userInfo(){
        this.setState({
            current : '/userinfo'
        })
        this.props.history.push('/userinfo');
    }

    loginOut(e){
        global._sessionStorage.removeItem('user');
        global._sessionStorage.removeItem('token');
        global._sessionStorage.removeItem('menuList');
        global._sessionStorage.removeItem('business');
        global._sessionStorage.removeItem('businessEncrypt');
        global._sessionStorage.removeItem('businessList');
        global._sessionStorage.removeItem('loginBusiness');
        global._sessionStorage.removeItem('loginInfoMap');
        global._sessionStorage.removeItem('random');
        global._sessionStorage.removeItem('userHeadId');
        this.props.history.push('/login');
    }

    render() {
        let user = global._sessionStorage.getItem("user");
        if(!user || "/login" === this.props.location.pathname){
            global._sessionStorage.removeItem("user");
            global._sessionStorage.removeItem("token");
            global._sessionStorage.removeItem("menuList");
            return <Redirect to="/login"/>
        }
        if("/" === this.props.location.pathname){
            return <Redirect to="/index"/>
        }

        const userMenu = (
            <Menu >
                <Menu.Item onClick={this.userInfo.bind(this)} key="passmodify"> <span><Icon style={{ marginRight : 10}} type="setting" />个人设置 </span> </Menu.Item>
                <Menu.Item onClick={this.loginOut.bind(this)} key="login"> <span><Icon style={{ marginRight : 10}} type="logout" />退出 </span> </Menu.Item>
            </Menu>
        );
        const businessListOptions = this.state.businessList.map(type => {
            return <Option key={type.key} value={type.key} >{type.value}</Option>
        })
        return (
                <Layout className="container-sider" >
                    <div style={{position : 'fixed',height :'100%', zIndex : '99'}}>
                        <Sider 
                            trigger ={null}
                            collapsible
                            collapsed = {this.state.collapsed}
                            style={{height :'100%'}}
                        >
                        {/* <Affix offsetTop="10" > */}
                            <div className="logo" style={ this.state.collapsed ? {} : {width : '200px'} } ></div>
                            <SiderMenu current={this.state.current} collapsed={this.state.collapsed} routesMenu={this.state.RoutesMenu}
                                siderClick={this.siderHandleClick.bind(this)} ref="setOpenKeys"/>
                        {/* </Affix> */}
                        </Sider>
                    </div>
                    <Layout style={ !this.state.collapsed ? {paddingLeft : '200px'} : {paddingLeft : '80px'}} >
                        <Header className="container-header">
                        <Row type="flex" justify="space-around" align="middle">
                            <Col lg={19} xxl={21} >
                                <Button type="primary" onClick={this.toggleCollapsed.bind(this)}
                                    style={{ marginLeft : 5}}>
                                    <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} />
                                </Button>
                                <Select style={{marginLeft : '20px'}} defaultValue={this.state.businessCode} 
                                        onChange={this.businessChange.bind(this)} className ='businessChange'>
                                    {businessListOptions}
                                </Select>
                                <img style={{marginLeft : '10px', height : '15px'}}  src={auth} alt="已认证"/>
                            </Col>
                            <Col span={1}  >
                                <div style={{cursor: 'pointer'}}>
                                    <HomeMessage/>
                                </div>
                            </Col>
                            <Col lg={4} xxl={2} >
                                <Dropdown  overlay={userMenu} placement="bottomCenter" >
                                <div style={{height:'50px',width: '100%',cursor: 'pointer'}} >
                                    <span className="ant-dropdown-link" ><Icon style={{ marginRight : 10}} type="user" />
                                    { this.state.username }<Icon type="down" style={{ marginLeft : 10}} /> </span>
                                </div>
                                </Dropdown>
                            </Col>
                        </Row>
                        </Header>
                        <Content>
                            <Card bordered size="small" >
                                <HomeCrumb RoutesLink={this.state.RoutesLink} currentPath={this.props.location.pathname} />
                            </Card>
                            <Card bordered className="container-content" >
                                <HomeRoutes RoutesLink={this.state.RoutesLink} />
                            </Card>
                            
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>
                        薪海保理平台版权 © Created by 薪海科技
                        </Footer>
                        <ForceChangePassword visible={this.state.changePassVisible} {...this.props} />
                    </Layout>
                </Layout>
        );
    }
}

export default Home;