import {http} from '../utils/http'

export function mobileSend(data){
    return http.post("/login/sendLoginSMS",data)
}

export function login(data){
    return http.post("/login/",data)
}

export function sendLoginSMSCollective(data){
    return http.post("/login/sendLoginSMSCollective",data)
}

export function loginCollective(data){
    return http.post("/login/loginCollective/",data)
}

export function synchTime(data){
    return http.post("/login/synchTime",data)
}

export function updateLastBusiness(data){
    return http.post("/login/updateLastBusiness",data)
}

export function getUserInfo(data){
    return http.post("/login/getUserInfo",data)
}