import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom'
import NoMatch from '../views/error/NoMatch'
import Async from '../components/Async'



class HomeRoutes extends Component {


    render() {
        const RouteMap = this.props.RoutesLink.map((menu,index) =>{
            return <Route key={index} exact path={menu.path} component = {Async(() => import('../views/'+menu.view))} />
        })
        if(RouteMap.length !== 0){
            RouteMap.push([<Route component={NoMatch} />]);
        }

        return (
            <Switch>
                {
                    RouteMap
                }
            </Switch>
        );
    }
}

export default HomeRoutes;