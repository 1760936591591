import React, { Component } from 'react';
import AppRoutes from './routes/AppRoutes'
import './config.js'

class App extends Component {

  render() {
    return (
      <AppRoutes/>
    );
  }
}

export default App;
