import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon } from 'antd';
import SelfIcon  from 'coms/Common/SelfIcon'
const SubMenu = Menu.SubMenu;


class SiderMenu extends Component {

    constructor(){
        super();
        this.state={
            openKeys : [],
            subOpenKeys : [],
        }
    }

    menuClick(e) {
        this.props.siderClick(e.key);
    }

    setOpenKeys(){
        this.setState({
            openKeys : [],
            subOpenKeys : [],
        })
    }

    subMenu(items){
        return items.map(item => {
            if(item.children){
                let title = <span>{this.getIcon(item.icon)}<span>{item.name}</span></span>;
                if(!item.icon){
                    title = <span><span>{item.name}</span></span>;
                }else{
                    title = <span>{this.getIcon(item.icon)}<span>{item.name}</span></span>;
                }
                return <SubMenu  key={item.path} title={ title }>
                            {this.subMenu(item.children)}
                        </SubMenu>;
            }else{
                return <Menu.Item key={item.path}>
                        <Link to={item.path}><span>{item.name}</span></Link>
                    </Menu.Item>
            }
            
        })
    }


    handlerOnOpenChange(openKeys){
        if(openKeys.length !== 0){
            const item = this.props.routesMenu.filter( menu => menu.path === openKeys[openKeys.length-1]);
            if( item.length !== 0 ){
                this.setState({
                    openKeys : [openKeys[openKeys.length-1]]
                })
            }else{
                const itemRoot = this.props.routesMenu.filter( menu => menu.path === openKeys[0]);
                if(itemRoot.length !== 0){
                    const subItem = itemRoot[0].children.filter( menu => menu.path === openKeys[openKeys.length-1]);
                    if(subItem !==0 ){
                        this.setState({
                            openKeys : [openKeys[0], openKeys[openKeys.length-1]]
                        })
                        
                    }else{
                        this.setState({
                            openKeys : openKeys
                        })
                    }
                }else{
                    this.setState({
                        openKeys : []
                    })
                }
            }
        }else{
            this.setState({
                openKeys : openKeys
            })
        }
    }

    getIcon = (icon) =>{
        if(icon && icon.indexOf("xh-") === 0) {
            return <SelfIcon className='self-icon' type={icon} />
        }else{
            return <Icon type={icon} />
        }

    }

    render() {
        const menuHtml = this.props.routesMenu.map(menu => {
            if(menu.children){
                let title = <span>{this.getIcon(menu.icon)}<span>{menu.name}</span></span>;
                return <SubMenu  key={menu.path} title={ title }>
                        {this.subMenu(menu.children)}
                    </SubMenu>;
            }else{
                return <Menu.Item key={menu.path}>
                    <Link to={menu.path}>
                        {this.getIcon(menu.icon)}
                    <span>{menu.name}</span></Link>
                </Menu.Item>
            }
        });

        return (
            <div className="sider-menu sider-menu-scroll">
                <div className="scrollbar">
                    <Menu theme="dark"
                    onClick={this.menuClick.bind(this)}
                    // openKeys={this.state.openKeys.concat(this.state.subOpenKeys)}
                    openKeys={this.state.openKeys}
                    selectedKeys = {[this.props.current] }
                    // defaultSelectedKeys={[this.props.current]}
                    mode="inline"
                    // inlineCollapsed={this.props.collapsed}
                    onOpenChange = {this.handlerOnOpenChange.bind(this)}
                    >
                    {menuHtml}
                    </Menu>
                </div>
            </div>
        );
    }
}

export default SiderMenu;