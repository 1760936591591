export const baseHttpUrl = "https://bl.51xinhai.com/factoringxh";
export const httpTimeOut = 300000;

export const fastDsfUrl = "https://ffds.51xinhai.com/";

export const kkFileUrl = "https://ffds.51xinhai.com/kkfile/"

export const kkFileKey = "XHapp803&!@7899";

export const publicKey = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAj9AaJMP8ZCOoDHbWR5OasP//g53BCwCY4c6Bt0BsxxEDu41y0Lc70m+sHEhUiH3ijGe6ZNGQPTuSCEynvgiCBfXeDInHrNuTRcDhnkQ3f1AtSJAtKzyNxOdKi5/+oWp+CyytUeA2vtltXfCFhDP6C5AagSIBnrDbJMn97bOwbOQJJuHnTseViUqtW7LPI/PJl+VQrIhjNosh7S0SLqIAJZ6Fynw4ggPSsK26W5GzgYKX8mRKM1I7oom8qxJcWeBmUEopkosbCiMaSBdgjymZx6lznUHSN69ZNsLRQIZCSz01qxs/JQpm+4SdX9kfl9uKtYmncmvPU1rpGfSJoK6StwIDAQAB`;

export const riskReport = "http://47.116.132.228:8088/risk/report/download/";