
import {http} from '../utils/http'

export function queryUserParam(data){
    return http.post("/sys/dictList",data);
}

export function queryUserList(data){
    return http.post("/user/queryUserList",data);
}

export function saveUser(data){
    return http.post("/user/saveUser",data);
}

export function updateUserInfo(data){
    return http.post("/user/updateUser",data);
}

export function updateLoginPwd(data){
    return http.post("/user/updateLoginPwd",data);
}

export function deleteUser(data){
    return http.post("/user/deleteUser",data);
}

export function resetLoginPwd(data){
    return http.post("/user/resetLoginPwd",data);
}

export function queryRoleList(data){
    return http.post("/role/queryListSimple",data);
}

export function queryUserDetail(data){
    return http.post("/user/queryUserDetail",data);
}

export function queryCertVaild(data){
    return http.post("/user/queryCertVaild",data);
}

export function queryMobileVaild(data){
    return http.post("/user/queryMobileVaild",data);
}

export function updateLoginCollectivePwd(data){
    return http.post("/user/updateLoginCollectivePwd",data);
}

export function qryBusinessAllUser(data){
    return http.post("/user/qryBusinessAllUser",data);
}