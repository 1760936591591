import EncryptAES from '@/utils/EncryptAES'
const _storageKey ='WGghQFBhc3M3ODk5JmZtdw==';

global.pageInfo = {
    sizeOptions: ['10','20','50','100'],
};

global._sessionStorage= {
    setItem:function(key,value,cb){
        let val = JSON.stringify(value);
        val = val || '' ;
        sessionStorage.setItem(`${key}`,`${EncryptAES.encrypt(val, _storageKey)}`);          
        return val;
    },
    getItem:function(key,cb){
        let value = sessionStorage.getItem(key) || '' ;
        if(!value){
            return null;
        }
        const decryptVal = EncryptAES.decrypt(value, _storageKey);
        if(!decryptVal){
            return null;
        }
        return JSON.parse(decryptVal);
    },
    removeItem:function(key,cb){
        sessionStorage.removeItem(key);
    },
    clear:function(cb){
      sessionStorage.clear();
    }
}