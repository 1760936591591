import React, { Component } from 'react';
import { Form, Input, Button, Icon, Message, Row , Col, Layout } from 'antd'
import { rsaEncrypt } from 'utils/EncryptRSA'
import 'assets/css/login.less'
import { sendLoginSMSCollective, loginCollective, synchTime } from 'api/login'

const FormItem = Form.Item;
const { Header, Footer } = Layout;

class LoginForm extends Component {
    constructor(){
        super();
        this.state={
            loginLoad : false,
            disabledBtn : false,
            mobileLoading : false,
            count : 60,
            mobileBtnText : '获取验证码',
            loginDisabled : false,
        }
    }

    componentWillMount() {
        global._sessionStorage.removeItem('user');
        global._sessionStorage.removeItem('token');
    }


    handlerSubmit(e){
        e.preventDefault();
        this.props.form.validateFields((err, params) => {
            if(!err){
                this.setState({
                    loginLoad : true
                })
                let loginParam = {  mobile : params.mobile, 
                                    loginPwd : rsaEncrypt.encrypt(params.password),
                                    messageCode : params.mobileCode,
                                    cip : global._sessionStorage.getItem("cip"),
                                }
                loginCollective(loginParam).then(res => {
                    let { errorCode,errorMessage,data } = res.data;
                    if(errorCode==='0000'){
                        global._sessionStorage.setItem('userHeadId',data.userHeadId);
                        global._sessionStorage.setItem('loginBusiness',data.loginBusiness);
                        global._sessionStorage.setItem('token',data.token);
                        global._sessionStorage.setItem('random',data.randomKey);
                        global._sessionStorage.setItem('businessList',JSON.stringify(data.businessList));
                        global._sessionStorage.setItem('loginInfoMap',JSON.stringify(data.loginInfoMap));
                        if(data.loginInfoMap[data.loginBusiness]){
                            let loginInfo = data.loginInfoMap[data.loginBusiness];
                            loginParam.businessCode = data.loginBusiness;
                            loginParam.userId = loginInfo.userId;
                            loginParam.roleId = loginInfo.roleId;
                            loginParam.branchId = loginInfo.branchId;
                            loginParam.isChangePass = data.isChangePass;
                            loginParam.lastLoginTime = data.lastLoginTime;
                            loginParam.personalSign = loginInfo.personalSign;
                            loginParam.companySign = loginInfo.companySign;
                            loginParam.username = loginInfo.userId;
                            loginParam.dataRange = loginInfo.dataRange;
                            global._sessionStorage.setItem('user',JSON.stringify(loginParam));
                            global._sessionStorage.setItem('menuList',JSON.stringify(loginInfo.menuTrees));
                            global._sessionStorage.setItem('businessEncrypt',loginInfo.businessEncrypt);
                            global._sessionStorage.setItem('business',JSON.stringify(loginInfo.businessInfo));
                        }
                        Message.success('登录成功');
                        this.clearInterval();
                        this.props.history.push('/index');
                    }else{
                        this.setState({
                            loginLoad : false
                        });
                        Message.error(errorMessage);
                    }
                })
            }
        })
    }

    sendMobile = () =>{
        this.props.form.validateFields(['mobile', 'password'], (err, params) => {
            if(!err){
                let postParam = {
                    mobile : params.mobile
                }
                this.setState({
                    mobileLoading : true,
                })
                sendLoginSMSCollective(postParam).then(res =>{
                    let { errorCode,errorMessage } = res.data
                    if(errorCode==='0000'){
                        Message.success('短信发送成功');
                        this.setState({
                            disabledBtn : true,
                            count : 60,
                            mobileBtnText : '重新获取'
                        })
                        this.setInterval();
                    }else{
                        Message.error(errorMessage);
                        this.setState({
                            mobileLoading : false,
                        })
                    }
                })
               
            }
        })
        
        
    }

    setInterval = () =>{
        this.timer = setInterval(this.countDown, 1000);
    }

    clearInterval = () =>{
        clearInterval(this.timer);
    }

    countDown = () =>{
        let count = this.state.count
        if(count === 1){
            this.clearInterval();
            this.setState({
                disabledBtn : false,
                mobileLoading : false,
            })
        }else{
            this.setState({
                count : count-1
            })
        }
    }

    getServerTime(){
        let params = {
            requestTime : new Date().getTime()
        }
        synchTime(params).then(res => {
            let { errorCode,data } = res.data;
            if(errorCode==='0000' && data.vaildTime ){
                global._sessionStorage.setItem('diffTime',data.systemTime);
            }else{
                this.setState({
                    loginDisabled : true
                });
            }
        })
    }

    componentDidMount(){
        this.getServerTime();
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Layout style={{height : '100%'}} >
            <Header className="login-header" >
                <div className ="login-logo" >
                
                </div>
                <div className="login-title" >
                    <span className="login-title-prefix" >
                    |
                    </span> 
                    <span>
                    业务服务平台
                    </span> 
                </div>
                
            </Header>
            
            <div className="login" >
                <div className="login-form" >
                    <div className ="login-logo" >
                    登 录
                    </div>
                    <Form onSubmit={this.handlerSubmit.bind(this)}  style ={{ maxWidth: '400px'}}  >
                        {/* <FormItem>
                                { getFieldDecorator('businessCode',{
                                    rules : [{ required: true, message: '请输入企业编号' }]
                                })(
                                    <Input prefix={ <Icon type='bank' style={{ fontSize : 14 }} /> } placeholder='企业编号' disabled={this.state.loginDisabled} />
                                ) }
                        </FormItem> */}
                        <FormItem>
                            { getFieldDecorator('mobile',{
                                rules : [{ required: true, message: '请输入手机号' }]
                            })(
                                <Input prefix={ <Icon type='phone' style={{ fontSize : 14 }} /> } placeholder='手机号' disabled={this.state.loginDisabled} />
                            ) }
                        </FormItem>
                        <FormItem>
                            { getFieldDecorator('password',{
                                rules : [
                                    { required: true,pattern: new RegExp('^(?![A-Za-z]+$)(?![A-Z\\d]+$)(?![A-Z\\W]+$)(?![a-z\\d]+$)(?![a-z\\W]+$)(?![\\d\\W]+$)\\S{6,12}$', "g"), message: '请输入6-12位密码' },
                                ]
                            })(
                                <Input type="password" prefix={ <Icon type='lock' style={{ fontSize : 14 }} /> } placeholder='密码' disabled={this.state.loginDisabled} />
                            ) }
                        </FormItem>
                        <FormItem>
                            <Row type="flex" justify="space-around" align="middle" >
                                <Col span={12} >
                                    { getFieldDecorator('mobileCode',{
                                        rules : [{ required: true , message: '请输入验证码' }]
                                    })(
                                        <Input prefix={ <Icon type='mobile' style={{ fontSize : 14 }}/> } placeholder='短信验证码' disabled={this.state.loginDisabled} />
                                    ) }

                                </Col>
                                <Col span={9} offset={3} >
                                    <Button type="primary" disabled={this.state.mobileLoading} onClick={this.sendMobile.bind(this)} style={{width : 100, paddingTop : 1}} >
                                    {this.state.disabledBtn ? `${this.state.count}秒`  : this.state.mobileBtnText}
                                    </Button>
                                </Col>
                            </Row>
                        </FormItem>
{/*                             
                            { getFieldDecorator('remember',{
                                valuePropName : 'checked',
                                initialValue : true
                            })(
                                <Checkbox>记住账号</Checkbox>
                            ) }
                            <a className="login-form-forgot" style={{float:'right'}} href="/">忘记密码</a> */}
                            <Button type='primary' loading={this.state.loginLoad} htmlType='submit' className="login-form-button" style={{width: '100%', marginBottom : '10px' }} >登录</Button>
                    </Form>
                    
                </div>
            </div>
                <Footer style={{ textAlign: 'center', backgroundColor : '#fff' }}>
                薪海保理平台版权 © Created by 薪海科技
                </Footer>
            </Layout>
            
            
            
        );
    }
}

const Login = Form.create()(LoginForm);
export default Login;